<template>
  <div class="d-flex justify-content-end align-items-center">
    <b-button variant="primary" class="mx-1" @click="submit">تعديل</b-button>
    <b-button @click="setIsBlock" variant="outline-danger" class="mr-1">{{
      dtoUpdateSellPoint.isBlocked ? "الغاء الحظر" : "حظر"
    }}</b-button>
    <b-button variant="danger" @click="deletePoint(dtoUpdateSellPoint.id)">حذف</b-button>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  computed: {
    ...mapState({
      dtoUpdateSellPoint: (state) => state.sellPoints.dtoUpdateSellPoint,
    }),
  },
  methods: {
    ...mapActions(["updateSellPoint", "blockSellPoint", "unblockSellPoint"]),
    deletePoint(id) {
      this.$store.dispatch("deleteRangeSellPoints", [id]);
    },
    submit() {
      this.updateSellPoint(this.dtoUpdateSellPoint);
    },
    setIsBlock() {
      if (!this.dtoUpdateSellPoint.isBlocked) {
        this.blockSellPoint(this.dtoUpdateSellPoint.id);
      } else this.unblockSellPoint(this.dtoUpdateSellPoint.id);
    },
  },
};
</script>
